.inputsearch {
  display: flex;
  flex: 1;
}

.lablesearch {
  background: linear-gradient(195deg, #aea9ab, #f05454) !important;
}

.appsearch {
  display: none;
}

.search {
  display: flex;
}

.pagination {
  margin-top: 2%;
  margin-bottom: 2%;
}

.detail {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.small {
  display: none;
}

.position {
  border-radius: 20px !important;
  display: flex !important;
  padding: 4px !important;
  margin-right: 10px !important;
  border: none !important;
  color: #344767 !important;
  background-color: #f7f7f7 !important;
  /* border: 3px solid #f05454 !important; */
}

.buttonclick {
  border-radius: 20px !important;
  display: flex !important;
  padding: 4px !important;
  margin-right: 10px !important;
  color: #f05454 !important;
  background-color: #d7d4d4 !important;
}

@media screen and (max-width: 768px) {
  .search {
    display: none;
    align-self: center;
  }

  .appsearch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .overflow {
    width: max-content;
    display: flex;
  }

  .custom-box {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    overflow-x: initial;
  }

  .pagination {
    width: max-content;
    transform: scale(0.8);
    float: right;
  }

  .inputsearch {
    display: block;
  }

  .inputcontent {
    width: 100%;
  }

  .avatar {
    display: none;
  }

  .full {
    display: none;
  }

  .small {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .search {
    transform: scale(0.9);
  }
}

@media screen and (max-width: 360px) {
  .search {
    transform: scale(0.8);
  }
}
