.card {
  transition: transform 0.5s;
}

.card:hover {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.category{
  background-color: #f05454 !important;
}
